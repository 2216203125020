import i18n from "@/i18n/i18n";

export const capitalizeStart = (s: string): string => {
  return s[0]?.toLocaleUpperCase() + s.substr(1);
};

export const capitalizeAllWords = (s: string): string => {
  const words = s.split(" ");
  return words.map((w: string) => capitalizeStart(w)).join(" ");
};

export const formatPrice = (
  price: number | string | undefined | null,
  currency?: string
): string => {
  if (price !== undefined && price !== null) {
    const formattedPrice = price
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
    if (currency) {
      return `${formattedPrice} ${currency}`;
    }

    return formattedPrice;
  }

  return "";
};

export const formatPriceRange = (
  priceFrom = 0,
  priceTo = 0,
  currency?: string
): string => {
  return `${formatPrice(priceFrom, currency)} - ${formatPrice(
    priceTo,
    currency
  )}`;
};

export const booleanTextValue = (value: boolean): string => {
  const textValue = value ? i18n.tc("yes", 1) : i18n.tc("no");
  return capitalizeStart(textValue);
};

export const booleanOrNaTexValue = (value: boolean | null): string => {
  let translation = "";
  switch (value) {
    case true: {
      translation = i18n.tc("yes", 1);
      break;
    }
    case false: {
      translation = i18n.tc("no", 1);
      break;
    }
    case null: {
      translation = i18n.tc("na", 1);
      break;
    }
  }
  return capitalizeStart(translation);
};

export const shortenText = (
  value: string,
  length = 99,
  suffix = "..."
): string => {
  const textLength = value.length;
  return textLength < 100 ? value : value.substring(0, length) + suffix;
};

export const splitAt = (text: string, index: number): [string, string] => {
  return [text.slice(0, index), text.slice(index)];
};
