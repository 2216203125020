import moment from "moment";
import { DATE_FORMAT, TIME_FORMAT } from "@/constants";

export const formatMoment = (
  moment: moment.Moment,
  withTime: boolean
): string => {
  if (withTime) {
    return moment.format(`${DATE_FORMAT} ${TIME_FORMAT}`);
  }
  return moment.format(DATE_FORMAT);
};

export const formatUnixTimestamp = (
  timestamp: any,
  withTime = false
): string => {
  const momentInstance = moment.unix(timestamp);

  if (!momentInstance.isValid()) {
    return "Invalid date";
  }

  return formatMoment(momentInstance, withTime);
};

export const formatTimestamp = (timestamp: any, withTime = false): string => {
  const momentInstance = moment(timestamp);

  if (!momentInstance.isValid()) {
    return "Invalid date";
  }

  return formatMoment(momentInstance, withTime);
};

export const formatTimestampAgo = (timestamp: number | string): string => {
  return moment(timestamp).fromNow();
};

export const formatUnixTimestampAgo = (timestamp: number): string => {
  return moment.unix(timestamp).fromNow();
};
