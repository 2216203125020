import { Module } from "vuex";
import { RootState } from "@/store/types";
import User, { UserRole } from "@/entity/User";
import UserService from "@/services/UserService";

interface AuthStore {
  user: User | null;
}

export default {
  namespaced: true,
  state: {
    user: null,
  },
  actions: {
    async getMe({ commit, state }): Promise<void> {
      if (!state.user) {
        const user = await UserService.getMe();
        commit("SET_USER", user);
      }
    },
  },
  mutations: {
    SET_USER: (state, user: User) => {
      state.user = user;
    },
  },
  getters: {
    isAdmin(state): boolean {
      if (!state.user) {
        return false;
      }
      return state.user.role === UserRole.ADMIN;
    },
    isProjectsAdmin(state): boolean {
      if (!state.user) {
        return false;
      }
      return state.user.role === UserRole.PROJECTS_ADMIN;
    },
  },
} as Module<AuthStore, RootState>;
