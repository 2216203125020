import Vue from "vue";
import App from "./App.vue";
import router from "./routing/Router";
import store from "./store/index";
import vuetify from "./plugins/Vuetify";
import i18n from "./i18n/i18n";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/styles/globalStyles.scss";
import Vuetify from "vuetify";
import VuetifyConfirm from "vuetify-confirm";
import "@/services/axiosInterceptor"; // attach interceptor
import "@/utils/globalFilters";
import storeActions from "@/plugins/storeActions";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(Vuetify);
Vue.use(storeActions);
Vue.use(VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
