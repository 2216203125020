import Axios from "axios";
import { ReferenceItem } from "@/services/types";

export enum FinancialItemType {
  INCOME_STATEMENT = 1,
  BALANCE = 2,
  DERIVED_INDICATORS = 3,
}

export default class GlobalService {
  public static async getRegions(): Promise<ReferenceItem[]> {
    const response = await Axios.get("/api/v2/dictionaries/regions");
    return response.data;
  }

  public static async getBuyerTypes(): Promise<ReferenceItem[]> {
    const response = await Axios.get("/api/v2/dictionaries/buyerTypes");
    return response.data;
  }

  public static async getSubIndustries(): Promise<ReferenceItem[]> {
    const response = await Axios.get("/api/v2/dictionaries/subIndustries");
    return response.data;
  }

  public static async getCompanyTypes(): Promise<ReferenceItem[]> {
    const response = await Axios.get("/api/v2/dictionaries/companyTypes");
    return response.data;
  }

  public static async getFinancialItems(
    type: FinancialItemType
  ): Promise<ReferenceItem[]> {
    const response = await Axios.get(
      `/api/v2/dictionaries/financialItems/${type}`
    );
    return response.data;
  }
}
