
















import { Vue, Component } from "vue-property-decorator";
import { Mutation, Getter } from "vuex-class";
import { Snackbar } from "@/store/snackbar";

@Component
export default class GlobalSnackbar extends Vue {
  @Getter("activeSnackbars", { namespace: "snackbar" })
  activeSnackbars!: Snackbar[];
  @Mutation("hideSnackbar", { namespace: "snackbar" }) hideSnackbar!: (
    index: number
  ) => void;
}
