import { Module } from "vuex";
import { RootState } from "@/store/types";
import { defaultState } from "./state";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import { ProjectValuationReportState } from "@/store/projectValuationReport/types";

export default {
  namespaced: true,
  state: {
    ...defaultState(),
  },
  mutations: {
    ...mutations,
  },
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
  },
} as Module<ProjectValuationReportState, RootState>;
