import Vue from "vue";

export interface Breadcrumb {
  text: string;
  href?: string | null;
  disabled?: boolean;
}

export interface SetToReplace {
  key: string;
  value: string;
}

export interface BreadcrumbsState {
  items: Breadcrumb[];
  toReplace: { [key: string]: string };
}

export default {
  namespaced: true,
  state: {
    items: [],
    toReplace: {},
  },
  getters: {
    getBreadcrumbs(state: BreadcrumbsState): Breadcrumb[] {
      const toReplace = state.toReplace;
      return state.items
        .map((item) => {
          let text = item.text;
          if (item.text[0] === ":") {
            text = toReplace[item.text] || item.text;
          }

          return {
            ...item,
            text,
          };
        })
        .filter((item) => {
          return item.text[0] !== ":";
        });
    },
  },
  mutations: {
    set(state: BreadcrumbsState, breadcrumbs: Breadcrumb[]): void {
      state.items = breadcrumbs;
    },
    setToReplace(state: BreadcrumbsState, toReplace: SetToReplace): void {
      Vue.set(state.toReplace, toReplace.key, toReplace.value);
    },
  },
};
