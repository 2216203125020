import User from "@/entity/User";

export enum IndustryType {
  INDUSTRY = 1,
  FUNCTIONAL_EXPERIENCE = 2,
  SUB_INDUSTRY = 3,
}

export interface CreateIndustry {
  name: string;
  aliases: string[];
  description: string;
  branchType: IndustryType;
}

export interface UpdateIndustry {
  name: string;
  aliases: string[];
  description: string;
}

export interface ContactIndustry {
  id: number;
  name: string;
  manual: boolean;
}

export default interface Industry {
  id?: number;
  aliases: string[] | null;
  branchType: IndustryType;
  createdAt: number;
  updatedAt: number | null;
  deletedAt: number | null;
  createdBy: number;
  description: string;
  name: string;
  user: User;
  industryRating: number | null;
}
