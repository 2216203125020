import { Module } from "vuex";
import { RootState } from "@/store/types";
import Notification from "@/entity/Notification";
import NotificationsService from "@/services/NotificationsService";
import { ALL_ITEMS_PER_PAGE } from "@/constants";

interface NotificationsState {
  notifications: Notification[];
  isMenuOpened: boolean;
  fetchInterval: number;
  totalUnread: number;
  isLoading: boolean;
}

export default {
  namespaced: true,
  state: {
    notifications: [],
    isMenuOpened: false,
    fetchInterval: 30000,
    totalUnread: 0,
    isLoading: false,
  },
  mutations: {
    setNotifications(state, notifications: Notification[]) {
      state.notifications = notifications;
    },
    removeNotification(state, index: number) {
      state.notifications.splice(index, 1);
      state.totalUnread -= 1;
    },
    setIsMenuOpened(state, isOpened: boolean) {
      state.isMenuOpened = isOpened;
    },
    setTotalUnread(state, unread: number) {
      state.totalUnread = unread;
    },
    setIsLoading(state, isLoading: boolean) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    async fetchNotifications({ commit }): Promise<void> {
      try {
        commit("setIsLoading", true);
        const response = await NotificationsService.find({
          itemsPerPage: ALL_ITEMS_PER_PAGE,
          filterBy: {
            dismissed: false,
          },
          sortBy: {
            createdAt: true,
          },
        });
        commit("setNotifications", response.content);
        commit("setTotalUnread", response.totalItems);
      } finally {
        commit("setIsLoading", false);
      }
    },
    async fetchTotalNotifications({ commit }): Promise<void> {
      const response = await NotificationsService.find({
        itemsPerPage: 0,
        filterBy: {
          dismissed: false,
        },
      });
      commit("setTotalUnread", response.totalItems);
    },
    async dismissAll({ commit }): Promise<void> {
      commit("setIsMenuOpened", false);
      commit("setNotifications", []);
      commit("setTotalUnread", 0);
      await NotificationsService.dismissAll();
    },
    async dismissNotification(
      { state, commit },
      notificationId: number
    ): Promise<void> {
      const isLast = state.notifications.length === 1;
      const notificationIndex = state.notifications.findIndex(
        (notification) => notification.id === notificationId
      );
      await NotificationsService.dismiss([notificationId]);
      if (isLast) {
        commit("setIsMenuOpened", false);
      }
      commit("removeNotification", notificationIndex);
    },
  },
} as Module<NotificationsState, RootState>;
