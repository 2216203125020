import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  return {
    de: require("@/i18n/de.json"),
  };
}

export default new VueI18n({
  locale: "de",
  silentFallbackWarn: true,
  messages: loadLocaleMessages(),
});
