import Axios from "axios";
import { ListResponseEntity } from "@/services/types";
import { TableQuery } from "@/components/data-table/types";
import filterQueryParams from "@/services/filterQueryParams";
import Industry, {
  CreateIndustry,
  IndustryType,
  UpdateIndustry,
} from "@/entity/Industry";
import { ALL_ITEMS_PER_PAGE } from "@/constants";
import { ProjectIndustryScoreWithProjects } from "@/entity/project-valuation/ProjectValuationScore";

export default class IndustryService {
  public static async find(
    query?: TableQuery
  ): Promise<ListResponseEntity<Industry>> {
    const response = await Axios.get("api/v2/dictionaries/industries", {
      params: {
        ...filterQueryParams(query),
      },
    });
    return response.data;
  }

  public static async findAllByType(type: IndustryType): Promise<Industry[]> {
    const response = await this.find({
      itemsPerPage: ALL_ITEMS_PER_PAGE,
      filterBy: {
        branchType: type,
      },
      sortBy: {
        name: false,
      },
    });
    return response.content;
  }

  public static async findOne(industryId: number): Promise<Industry> {
    const response = await Axios.get(
      `api/v2/dictionaries/industries/${industryId}`
    );
    return response.data;
  }

  public static async create(data: CreateIndustry): Promise<Industry> {
    const response = await Axios.post("api/v2/dictionaries/industries", data);
    return response.data;
  }

  public static async update(
    industryId: number,
    data: UpdateIndustry
  ): Promise<Industry> {
    const response = await Axios.put(
      `api/v2/dictionaries/industries/${industryId}`,
      data
    );
    return response.data;
  }

  public static async deactivate(industryId: number): Promise<Industry> {
    const response = await Axios.delete(
      `api/v2/dictionaries/industries/${industryId}`
    );
    return response.data;
  }

  public static async activateMany(industryIds: number[]): Promise<void> {
    const response = await Axios.post(
      `api/v2/dictionaries/industries/restore`,
      {},
      {
        params: {
          ids: industryIds.toString(),
        },
      }
    );
    return response.data;
  }

  public static async getIndustryScoring(
    industryId: number
  ): Promise<ProjectIndustryScoreWithProjects> {
    const response = await Axios.get(
      `api/v2/dictionaries/industries/scoring/${industryId}`
    );
    return response.data;
  }
}
