import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";

import breadcrumbs from "@/store/breadcrumbs";
import selectOptions from "@/store/selectOptions";
import auth from "@/store/auth";
import notifications from "@/store/notifications";
import snackbar from "@/store/snackbar";
import projectValuationReport from "@/store/projectValuationReport/index";

Vue.use(Vuex);

const store = {
  state: {
    version: "1.0.0",
  },
  modules: {
    breadcrumbs,
    selectOptions,
    auth,
    notifications,
    snackbar,
    projectValuationReport,
  },
} as StoreOptions<RootState>;

export default new Vuex.Store(store);
