import { Module } from "vuex";
import { RootState } from "@/store/types";
import { SNACKBAR_TIMEOUT } from "@/constants";

export enum SnackbarTypes {
  INFO = "info",
  ERROR = "error",
  SUCCESS = "success",
}

export interface Snackbar {
  text: string;
  type: SnackbarTypes;
  showing: boolean;
}

export interface SetSnackbar {
  text: string;
  type: SnackbarTypes;
}

interface SnackbarState {
  snackbars: Snackbar[];
  timeout: number;
}

export default {
  namespaced: true,
  state: {
    snackbars: [],
    timeout: SNACKBAR_TIMEOUT,
  },
  mutations: {
    setSnackbar(state, snackbar: Snackbar) {
      state.snackbars = state.snackbars.concat(snackbar);
    },
    hideSnackbar(state, index: number) {
      state.snackbars[index].showing = false;
    },
  },
  actions: {
    setSnackbar({ commit }, snackbar: SetSnackbar) {
      commit("setSnackbar", {
        ...snackbar,
        type: snackbar.type || SnackbarTypes.SUCCESS,
        showing: true,
      });
    },
  },
  getters: {
    activeSnackbars(state): Snackbar[] {
      return state.snackbars.filter((snackbar) => snackbar.showing);
    },
  },
} as Module<SnackbarState, RootState>;
