import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { ProjectValuationReportState } from "@/store/projectValuationReport/types";
import { BUYER_TYPE_DB_NAMES } from "@/constants";

export default {
  getCompanyNumber(state) {
    return state.userInput.companyNumber;
  },
  getClient(state) {
    return state.userInput.client;
  },
  getValuationDate(state) {
    return state.userInput.valuationDate;
  },
  getCreationDate(state) {
    return state.userInput.creationDate;
  },
  getMethodQuote(state) {
    return state.userInput.methodQuote;
  },
  getMarketPriceQuote(state) {
    return state.userInput.marketPriceQuote;
  },
  getObservations(state) {
    return state.userInput.observations;
  },
  getObservationTable(state) {
    return state.userInput.observationsTable;
  },
  getMarketValue(state) {
    return state.userInput.marketValue;
  },
  getBuyerDatabaseQuote(state) {
    return state.userInput.buyerDatabaseQuote;
  },
  getEvaluationOfBuyerDatabase(state) {
    return state.userInput.evaluationOfBuyerDatabase;
  },
  getBuyerTypes(state) {
    return state.userInput.buyerTypes;
  },
  getPrivateBuyer(state) {
    return (
      state.userInput.buyerTypes.find(
        (item) => item.name === BUYER_TYPE_DB_NAMES.privateBuyer
      )?.count || 0
    );
  },
  getStrategicBuyer(state) {
    return (
      state.userInput.buyerTypes.find(
        (item) => item.name === BUYER_TYPE_DB_NAMES.strategicBuyer
      )?.count || 0
    );
  },
  getFinancialInvestor(state) {
    return (
      state.userInput.buyerTypes.find(
        (item) => item.name === BUYER_TYPE_DB_NAMES.financialInvestor
      )?.count || 0
    );
  },
  getBtScoreQuote(state) {
    return state.userInput.btScoreQuote;
  },
  getService(state) {
    return state.userInput.service;
  },
  getConclusionQuote(state) {
    return state.userInput.conclusionQuote;
  },
  getConclusionMarketPrice(state) {
    return state.userInput.conclusionMarketPrice;
  },
  getConclusionBuyerDatabase(state) {
    return state.userInput.conclusionBuyerDatabase;
  },
  getConclusionBtScore(state) {
    return state.userInput.conclusionBtScore;
  },
  getEndQuote(state) {
    return state.userInput.endQuote;
  },
  getPeerGroup(state) {
    return state.userInput.peerGroup;
  },
  getAdjustments(state) {
    return state.userInput.adjustments;
  },
  getEndTimeline(state) {
    return state.userInput.endTimeline;
  },
  getUserInput(state) {
    return state.userInput;
  },
  getIsSavingUserInput(state) {
    return state.isSavingUserInput;
  },
} as GetterTree<ProjectValuationReportState, RootState>;
