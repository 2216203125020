import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import routerSettings from "./RouterSettings";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: routerSettings,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta?.guest === true) {
    next();
  } else {
    try {
      await store.dispatch("auth/getMe");
      next();
    } catch (e) {
      next({ path: "/login" });
    }
  }
});

export const onlyAdmin = (to: Route, from: Route, next: any) => {
  if (store.getters["auth/isAdmin"]) {
    next();
  } else {
    next({ name: "403" });
  }
};

export default router;
