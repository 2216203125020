import Vue from "vue";
import { MutationTree } from "vuex";
import {
  EditAdjustmentPayload,
  EditEndTimelineItemPayload,
  EditPeerGroupItemPayload,
  MutationStringByIndexPayload,
  ProjectValuationReportEndTimelineItem,
  ProjectValuationReportState,
  ProjectValuationReportUserInput,
} from "@/store/projectValuationReport/types";
import { alphabet, BUYER_TYPE_DB_NAMES } from "@/constants";
import { defaultUserInput } from "@/store/projectValuationReport/state";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";

export default {
  setCompanyNumber(state, value: string) {
    state.userInput.companyNumber = value;
  },
  setClient(state, value: string) {
    state.userInput.client = value;
  },
  setValuationDate(state, value: string) {
    state.userInput.valuationDate = value;
  },
  setCreationDate(state, value: string) {
    state.userInput.creationDate = value;
  },
  setMethodQuote(state, value: string) {
    state.userInput.methodQuote = value;
  },
  setMarketPriceQuote(state, value: string) {
    state.userInput.marketPriceQuote = value;
  },
  setObservations(state, value: string) {
    state.userInput.observations = value;
  },
  setObservationValueDriver(state, payload: MutationStringByIndexPayload) {
    state.userInput.observationsTable[payload.index].valueDriver =
      payload.value;
  },
  setObservationRiskDriver(state, payload: MutationStringByIndexPayload) {
    state.userInput.observationsTable[payload.index].riskDriver = payload.value;
  },
  setMarketValue(state, value: string) {
    state.userInput.marketValue = value;
  },
  setBuyerDatabaseQuote(state, value: string) {
    state.userInput.buyerDatabaseQuote = value;
  },
  setEvaluationOfBuyerDatabase(state, value: string) {
    state.userInput.evaluationOfBuyerDatabase = value;
  },
  setBuyerTypes(state, value: ProjectValuationBuyerChart[]) {
    state.userInput.buyerTypes = value;
  },
  setBuyerType(
    state,
    value: {
      name: BUYER_TYPE_DB_NAMES;
      newCount: number;
    }
  ) {
    state.userInput.buyerTypes = state.userInput.buyerTypes.map((item) => {
      if (item.name === value.name) {
        return { ...item, count: value.newCount };
      }
      return item;
    });
  },
  setBtScoreQuote(state, value: string) {
    state.userInput.btScoreQuote = value;
  },
  setService(state, value: string) {
    state.userInput.service = value;
  },
  setConclusionQuote(state, value: string) {
    state.userInput.conclusionQuote = value;
  },
  setConclusionMarketPrice(state, value: string) {
    state.userInput.conclusionMarketPrice = value;
  },
  setConclusionBuyerDatabase(state, value: string) {
    state.userInput.conclusionBuyerDatabase = value;
  },
  setConclusionBtScore(state, value: string) {
    state.userInput.conclusionBtScore = value;
  },
  setEndQuote(state, value: string) {
    state.userInput.endQuote = value;
  },
  editPeerGroupItem(state, payload: EditPeerGroupItemPayload) {
    state.userInput.peerGroup.splice(payload.index, 1, payload.item);
  },
  editEndTimelineItem(state, payload: EditEndTimelineItemPayload) {
    state.userInput.endTimeline.items.splice(payload.index, 1, payload.item);
  },
  addEndTimelineItem(state) {
    state.userInput.endTimeline.items.push({
      title: "",
      text: "",
    } as ProjectValuationReportEndTimelineItem);
  },
  deleteEndTimelineItem(state, index: number) {
    state.userInput.endTimeline.items.splice(index, 1);
  },
  setEndTimelineEnd(state, value: string) {
    state.userInput.endTimeline.end = value;
  },
  initializeAdjustments(state, length: number) {
    for (let i = 1; i <= length; i++) {
      Vue.set(state.userInput.adjustments, alphabet.charAt(i - 1), "");
    }
  },
  editAdjustment(state, payload: EditAdjustmentPayload) {
    Vue.set(state.userInput.adjustments, payload.key, payload.value);
  },
  deleteAdjustment(state, key: string) {
    Vue.delete(state.userInput.adjustments, key);
  },
  setUserInputData(state, payload: ProjectValuationReportUserInput) {
    Vue.set(state, "userInput", payload);
  },
  resetUserInput(state) {
    Vue.set(state, "userInput", defaultUserInput());
  },
} as MutationTree<ProjectValuationReportState>;
