import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { ProjectValuationReportState } from "@/store/projectValuationReport/types";
import ProjectValuationSnapshotService from "@/services/projectValuation/ProjectValuationSnapshotService";

export default {
  async saveReportInputs({ state }, projectId: number) {
    await ProjectValuationSnapshotService.saveUserInput(
      projectId,
      state.userInput
    );
  },
} as ActionTree<ProjectValuationReportState, RootState>;
