import Vue from "vue";
import {
  capitalizeStart,
  capitalizeAllWords,
  formatPrice,
  booleanTextValue,
  formatPriceRange,
  booleanOrNaTexValue,
  shortenText,
} from "./string";
import { pluckAndJoin } from "./array";
import {
  formatTimestamp,
  formatUnixTimestamp,
  formatTimestampAgo,
  formatUnixTimestampAgo,
} from "./date";

// String
Vue.filter("capitalizeStart", capitalizeStart);
Vue.filter("capitalizeAllWords", capitalizeAllWords);
Vue.filter("formatPrice", formatPrice);
Vue.filter("booleanTextValue", booleanTextValue);
Vue.filter("formatPriceRange", formatPriceRange);
Vue.filter("booleanOrNaTexValue", booleanOrNaTexValue);
Vue.filter("shortenText", shortenText);

// Array
Vue.filter("pluckAndJoin", pluckAndJoin);

// Date
Vue.filter("formatTimestamp", formatTimestamp);
Vue.filter("formatUnixTimestamp", formatUnixTimestamp);
Vue.filter("formatTimestampAgo", formatTimestampAgo);
Vue.filter("formatUnixTimestampAgo", formatUnixTimestampAgo);
