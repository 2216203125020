import {
  ProjectValuationReportPeerGroupItem,
  ProjectValuationReportState,
  ProjectValuationReportUserInput,
} from "@/store/projectValuationReport/types";

const createPeerGroupState = (): ProjectValuationReportPeerGroupItem[] => {
  return Array(4).fill({
    name: "",
    value: null,
    overallValue: null,
    comparisonKey: "",
    ratioKey: "",
    yearFrom: new Date().getFullYear(),
    yearTo: new Date().getFullYear(),
    branchIds: [],
    contactIds: [],
  });
};

export const defaultUserInput = (): ProjectValuationReportUserInput => {
  return {
    companyNumber: "",
    client: "",
    valuationDate: "",
    creationDate: "",
    methodQuote: "",
    marketPriceQuote: "",
    normalisation: [],
    observations: "",
    observationsTable: [
      {
        valueDriver: "",
        riskDriver: "",
      },
      {
        valueDriver: "",
        riskDriver: "",
      },
      {
        valueDriver: "",
        riskDriver: "",
      },
    ],
    marketValue: "",
    buyerDatabaseQuote: "",
    evaluationOfBuyerDatabase: "",
    btScoreQuote: "",
    service: "",
    conclusionQuote: "",
    conclusionMarketPrice: "",
    conclusionBuyerDatabase: "",
    conclusionBtScore: "",
    endQuote: "",
    peerGroup: createPeerGroupState(),
    endTimeline: {
      end: "",
      items: [],
    },
    adjustments: {},
    buyerTypes: [],
  };
};

export const defaultState = (): ProjectValuationReportState => {
  return {
    isSavingUserInput: false,
    userInput: defaultUserInput(),
  };
};
