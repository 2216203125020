import Vue from "vue";
import { SnackbarTypes } from "@/store/snackbar";
import { TranslateResult } from "vue-i18n";
import { SetToReplace } from "@/store/breadcrumbs";

export default {
  install: function (vue: typeof Vue) {
    vue.prototype.$snackbarSuccess = function (text: string | TranslateResult) {
      this.$store.dispatch("snackbar/setSnackbar", {
        text,
        type: SnackbarTypes.SUCCESS,
      });
    };

    vue.prototype.$snackbarError = function (text: string | TranslateResult) {
      this.$store.dispatch("snackbar/setSnackbar", {
        text,
        type: SnackbarTypes.ERROR,
      });
    };

    vue.prototype.$replaceBreadcrumb = function (value: SetToReplace) {
      this.$store.commit("breadcrumbs/setToReplace", value);
    };

    vue.prototype.$isDev = function () {
      return process.env.NODE_ENV === "development";
    };
  },
};

declare module "vue/types/vue" {
  interface Vue {
    $snackbarSuccess: (text: string | TranslateResult) => void;
    $snackbarError: (text: string | TranslateResult) => void;
    $replaceBreadcrumb: (value: SetToReplace) => void;
    $isDev: () => boolean;
  }
}
