import { TableQuery, SortBy, FilterBy } from "@/components/data-table/types";
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_ITEMS_PER_PAGE,
  ALL_ITEMS_PER_PAGE,
} from "@/constants";

interface FilterQueryParams {
  [key: string]: string | undefined;
}

const sortString = (sort: SortBy): string | undefined => {
  const sortValues: string[] = [];
  Object.keys(sort).forEach((key) => {
    const prefix: string = sort[key] ? "-" : "";
    sortValues.push(`${prefix}${key}`);
  });

  if (sortValues.length === 0) {
    return undefined;
  }
  return sortValues.toString();
};

export const normalizeFilterParameters = (filter: FilterBy): FilterBy => {
  const keys = Object.keys(filter);
  const filterBy: FilterBy = {};

  for (const key of keys) {
    const filterValue = filter[key];

    if (Array.isArray(filterValue) && filterValue.length > 0) {
      filterBy[key] = filterValue.toString();
      continue;
    }

    if (filterValue !== undefined && filterValue !== null) {
      filterBy[key] = filterValue;
    }
  }

  return filterBy;
};

export default (query: TableQuery = {}): FilterQueryParams => {
  const {
    page = DEFAULT_INITIAL_PAGE,
    itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
    filterBy = {},
    sortBy = {},
  } = query;
  let actualItemsPerPage = itemsPerPage;

  if (query.itemsPerPage === -1) {
    actualItemsPerPage = ALL_ITEMS_PER_PAGE;
  }

  return {
    page: page.toString(),
    itemsPerPage: actualItemsPerPage.toString(),
    ...normalizeFilterParameters(filterBy),
    sort: sortString(sortBy),
  };
};
