import GlobalService from "@/services/GlobalService";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import { ReferenceItem } from "@/services/types";
import { ALL_ITEMS_PER_PAGE } from "@/constants";
import Industry, { IndustryType } from "@/entity/Industry";
import IndustryService from "@/services/IndustryService";
import { orderBy } from "lodash";

interface SelectOptionsState {
  regions: ReferenceItem[];
  industries: Industry[];
  subIndustries: ReferenceItem[];
  buyerTypes: ReferenceItem[];
}

export const SET_REGIONS = "SET_REGIONS";
export const SET_INDUSTRIES = "SET_INDUSTRIES";
export const SET_BUYER_TYPES = "SET_BUYER_TYPES";
export const SET_SUB_INDUSTRIES = "SET_SUB_INDUSTRIES";

export default {
  namespaced: true,
  state: {
    regions: [],
    industries: [],
    subIndustries: [],
    buyerTypes: [],
  },
  mutations: {
    [SET_REGIONS](state, value: ReferenceItem[]) {
      state.regions = value;
    },
    [SET_INDUSTRIES](state, value: Industry[]) {
      state.industries = value;
    },
    [SET_BUYER_TYPES](state, value: ReferenceItem[]) {
      state.buyerTypes = value;
    },
    [SET_SUB_INDUSTRIES](state, value: ReferenceItem[]) {
      state.subIndustries = value;
    },
  },
  actions: {
    async fetchIndustries({ commit }): Promise<void> {
      const industries = await IndustryService.find({
        itemsPerPage: ALL_ITEMS_PER_PAGE,
        filterBy: {
          branchType: IndustryType.INDUSTRY,
        },
      });
      commit(SET_INDUSTRIES, orderBy(industries.content, ["name"]));
    },
    async fetchSubIndustries({ commit }): Promise<void> {
      const subIndustries = await GlobalService.getSubIndustries();
      commit(SET_SUB_INDUSTRIES, subIndustries);
    },
    async fetchRegions({ commit }): Promise<void> {
      const regions = await GlobalService.getRegions();
      commit(SET_REGIONS, regions);
    },
    async fetchBuyerTypes({ commit }): Promise<void> {
      const buyerTypes = await GlobalService.getBuyerTypes();
      commit(SET_BUYER_TYPES, buyerTypes);
    },
  },
} as Module<SelectOptionsState, RootState>;
