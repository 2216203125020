import { RouteConfig } from "vue-router";
import { onlyAdmin } from "@/routing/Router";
import { getFirstQueryValue } from "@/utils/queryParams";

const routerSettings = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("@/views/ForgotPasswordView.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/password-reset",
    name: "passwordReset",
    component: () => import("@/views/PasswordResetView.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/upload-nda",
    name: "uploadNda",
    component: () => import("@/views/NdaFormView.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/download-expose",
    name: "downloadExpose",
    component: () => import("@/components/expose-view/DownloadExposeView.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/reject-expose",
    name: "rejectExpose",
    component: () => import("@/views/ExposeRejectionView.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/teaser-yes",
    meta: {
      guest: true,
    },
    component: () => import("@/views/TeaserResponseYesView.vue"),
  },
  {
    path: "/teaser-no",
    meta: {
      guest: true,
    },
    component: () => import("@/views/TeaserResponseNoView.vue"),
  },
  {
    path: "/eoi",
    name: "expressionOfInterest",
    component: () => import("@/views/ExpressionOfInterestView.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/eoi-preview",
    name: "expressionOfInterestPreview",
    component: () => import("@/views/ExpressionOfInterestPreviewView.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/eoi-preview-v2",
    name: "expressionOfInterestPreview",
    component: () => import("@/views/ExpressionOfInterestPreviewViewV2.vue"),
    meta: {
      guest: true,
    },
  },
  // projectValuationReport must be above projects else its not properly resolved because of :projectType
  {
    name: "projectValuationReport",
    path: "/projects/valuation-report/:token",
    component: () => import("@/views/ProjectValuationReportView.vue"),
    meta: {
      guest: true,
    },
    props: (route) => ({
      token: getFirstQueryValue(route.params.token),
    }),
  },
  {
    name: "projectBuyersStatisticsReport",
    path: "/projects/buyer-statistics-report/:token",
    component: () => import("@/views/ProjectBuyerStatisticsReportView.vue"),
    meta: {
      guest: true,
    },
    props: (route) => ({
      token: getFirstQueryValue(route.params.token),
    }),
  },
  {
    path: "/",
    component: () => import("@/layouts/MainLayout.vue"),
    children: [
      {
        path: "/",
        redirect: "home",
      },
      {
        path: "home",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "projects",
        component: () => import("@/layouts/ProjectsLayout.vue"),
        children: [
          {
            path: "/",
            name: "projects",
            redirect: "transaction",
          },
          {
            name: "transactionProjects",
            path: "transaction",
            component: () => import("@/views/ProjectsView.vue"),
          },
          {
            name: "valuationProjects",
            path: "valuation",
            component: () => import("@/views/ProjectsView.vue"),
          },
        ],
      },
      {
        name: "project",
        path: "projects/:projectType/:projectId",
        component: () => import("@/layouts/ProjectLayout.vue"),
        props: (route) => ({
          projectId: parseInt(route.params.projectId),
        }),
        children: [
          {
            name: "projectDetail",
            path: "detail",
            component: () => import("@/views/ProjectDetailView.vue"),
          },
          {
            name: "projectBuyers",
            path: "buyers",
            component: () => import("@/views/ProjectBuyersView.vue"),
          },
          {
            name: "projectHistory",
            path: "history",
            component: () => import("@/views/ProjectHistoryView.vue"),
          },
          {
            name: "projectHistoryDetail",
            path: "history/:historyId",
            component: () => import("@/views/ProjectHistoryView.vue"),
          },
          {
            name: "projectStatistics",
            path: "statistics",
            component: () => import("@/views/ProjectStatisticsView.vue"),
          },
          {
            name: "finance",
            path: "finance",
            component: () => import("@/views/ProjectFinanceView.vue"),
          },
          {
            name: "projectSales",
            path: "sales",
            component: () => import("@/views/ProjectSalesView.vue"),
          },
          {
            path: "valuation",
            name: "projectValuation",
            component: () => import("@/views/ProjectValuationView.vue"),
          },
        ],
      },
      {
        path: "contacts",
        name: "contacts",
        component: () => import("@/views/ContactsView.vue"),
      },
      {
        path: "contacts/:contactId",
        name: "contact",
        component: () => import("@/layouts/ContactLayout.vue"),
        props: (route) => ({
          contactId: parseInt(route.params.contactId),
        }),
        children: [
          {
            name: "contactDetail",
            path: "detail",
            component: () => import("@/views/ContactDetailView.vue"),
          },
          {
            name: "contactProspects",
            path: "prospects",
            component: () => import("@/views/ContactProspectsView.vue"),
          },
          {
            name: "contactSearchProfiles",
            path: "searchProfiles",
            component: () => import("@/views/ContactSearchProfilesView.vue"),
          },
          {
            name: "contactHistory",
            path: "history",
            component: () => import("@/views/ContactHistoryView.vue"),
          },
          {
            name: "contactHistoryDetail",
            path: "history/:historyId",
            component: () => import("@/views/ContactHistoryView.vue"),
            props: (route) => ({
              historyId: parseInt(route.params.historyId),
            }),
          },
        ],
      },
      {
        path: "companies",
        name: "companies",
        component: () => import("@/views/CompaniesView.vue"),
      },
      {
        name: "company",
        path: "companies/:companyId",
        component: () => import("@/layouts/CompanyLayout.vue"),
        props: (route) => ({
          companyId: parseInt(route.params.companyId),
        }),
        children: [
          {
            name: "companyDetail",
            path: "detail",
            component: () => import("@/views/CompanyDetailView.vue"),
          },
          {
            name: "companyHistory",
            path: "history",
            component: () => import("@/views/CompanyHistoryView.vue"),
          },
          {
            name: "companyHistoryDetail",
            path: "history/:historyId",
            component: () => import("@/views/CompanyHistoryView.vue"),
            props: (route) => ({
              historyId: parseInt(route.params.historyId),
            }),
          },
          {
            name: "companyProspects",
            path: "prospects",
            component: () => import("@/views/CompanyProspectsView.vue"),
          },
          {
            name: "companySearchProfiles",
            path: "searchProfiles",
            component: () => import("@/views/ContactSearchProfilesView.vue"),
          },
        ],
      },
      {
        path: "analytics",
        name: "analytics",
        component: () => import("@/views/AnalyticsView.vue"),
      },
      {
        path: "consultants",
        name: "consultants",
        component: () => import("@/views/ConsultantsView.vue"),
        beforeEnter: (to, from, next) => onlyAdmin(to, from, next),
      },
      {
        path: "settings",
        component: () => import("@/views/SettingsView.vue"),
        beforeEnter: (to, from, next) => onlyAdmin(to, from, next),
      },
      {
        path: "library",
        component: () => import("@/views/LibraryView.vue"),
        beforeEnter: (to, from, next) => onlyAdmin(to, from, next),
      },
      {
        path: "market-analysis",
        component: () => import("@/views/MarketAnalysisView.vue"),
      },
      {
        path: "dictionaries",
        component: () => import("@/layouts/DictionariesLayout.vue"),
        beforeEnter: (to, from, next) => onlyAdmin(to, from, next),
        children: [
          {
            path: "industries",
            name: "dictionaryIndustries",
            component: () => import("@/views/DictionariesView.vue"),
          },
          {
            path: "subIndustries",
            name: "dictionarySubIndustries",
            component: () => import("@/views/DictionariesView.vue"),
          },
          {
            path: "functional",
            name: "dictionaryFunctionalExperience",
            component: () => import("@/views/DictionariesView.vue"),
          },
        ],
        redirect: {
          name: "dictionaryIndustries",
        },
      },
      {
        path: "404",
        name: "404",
        component: () => import("@/views/NotFoundView.vue"),
      },
      {
        path: "403",
        name: "403",
        component: () => import("@/views/ForbiddenView.vue"),
      },
      {
        path: "/send-expose",
        name: "sendExpose",
        component: () => import("@/views/SendExposeView.vue"),
        meta: {
          guest: true,
        },
      },
      {
        path: "/search-profile",
        name: "searchProfile",
        component: () => import("@/views/SearchProfileView.vue"),
        meta: {
          guest: true,
        },
      },
    ],
  },
  {
    path: "*",
    name: "notFound",
    redirect: { name: "404" },
  },
] as RouteConfig[];

export default routerSettings;
