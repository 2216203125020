export const getFirstQueryValue = (
  queryValue: string | (string | null)[]
): string | undefined => {
  if (queryValue === undefined || queryValue === null) return undefined;

  if (Array.isArray(queryValue)) {
    const firstParamValue = queryValue[0];
    return firstParamValue === null ? "" : firstParamValue;
  } else {
    return queryValue;
  }
};

export const getFirstQueryValueAsInt = (
  queryValue: string | (string | null)[]
): undefined | number => {
  const value = getFirstQueryValue(queryValue);

  if (value !== undefined) {
    return parseInt(value);
  }

  return value;
};
