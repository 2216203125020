import Axios from "axios";
import { ProjectValuationReportUserInput } from "@/store/projectValuationReport/types";
import {
  ProjectValuationSnapshot,
  ProjectValuationSnapshotVersion,
} from "@/entity/project-valuation/ProjectValuationSnapshot";
import {
  ProjectValuationBuyerStats,
  ProjectValuationSnapshotBuyers,
} from "@/entity/project-valuation/ProjectValuationBuyers";
import { TableQuery } from "@/components/data-table/types";
import { ListResponseEntity } from "@/services/types";
import filterQueryParams from "@/services/filterQueryParams";

export default class ProjectValuationSnapshotService {
  static async create(projectId: number, name: string): Promise<number> {
    const { data } = await Axios.post(
      `/api/v2/valuation/${projectId}/snapshot`,
      {
        name,
      }
    );
    return data;
  }

  static async edit(
    projectId: number,
    snapshotId: number,
    name: string
  ): Promise<number> {
    const { data } = await Axios.patch(
      `/api/v2/valuation/${projectId}/snapshot/${snapshotId}`,
      {
        name,
      }
    );
    return data;
  }

  static async remove(projectId: number, snapshotId: number): Promise<number> {
    const { data } = await Axios.delete(
      `/api/v2/valuation/${projectId}/snapshot/${snapshotId}`
    );
    return data;
  }

  static async versions(
    projectId: number
  ): Promise<ProjectValuationSnapshotVersion[]> {
    const { data } = await Axios.get(
      `/api/v2/valuation/${projectId}/snapshot/versions`
    );
    return data;
  }

  static async get(
    projectId: number,
    snapshotId: number
  ): Promise<ProjectValuationSnapshot> {
    const { data } = await Axios.get(
      `/api/v2/valuation/${projectId}/snapshot/${snapshotId}`
    );
    return data;
  }

  static async saveUserInput(
    projectId: number,
    state: ProjectValuationReportUserInput
  ): Promise<ProjectValuationSnapshot> {
    const { data } = await Axios.post(
      `/api/v2/valuation/${projectId}/snapshot/userInput`,
      state
    );
    return data;
  }

  static async updateBuyerStats(
    projectId: number,
    snapshotId: number,
    payload: ProjectValuationBuyerStats
  ): Promise<ProjectValuationSnapshot> {
    const { data } = await Axios.patch(
      `/api/v2/valuation/${projectId}/snapshot/${snapshotId}/buyer-stats`,
      payload
    );
    return data;
  }

  static async listBuyers(
    projectId: number,
    snapshotId: number,
    query: TableQuery
  ): Promise<ListResponseEntity<ProjectValuationSnapshotBuyers>> {
    const response = await Axios.get(
      `/api/v2/valuation/${projectId}/snapshot/${snapshotId}/buyers`,
      {
        params: {
          ...filterQueryParams(query),
        },
      }
    );

    return response.data;
  }
}
