export const pluckAndJoin = (array: any[], key: string): string => {
  if (!Array.isArray(array)) {
    return "";
  }

  return array.map((item) => item[key]).join(", ");
};

export const calculateAverage = (
  values: number[],
  includeZeroValues = false
): number => {
  let totalValue = 0;
  let validRows = 0;

  values.forEach((value) => {
    if (!isNaN(value) && (!includeZeroValues ? value !== 0 : true)) {
      totalValue = totalValue + value;
      validRows++;
    }
  });

  const result = totalValue / validRows;

  if (isNaN(result)) {
    return 0;
  }

  return result;
};

export const calculateMedian = (values: number[]): number => {
  if (values.length === 0) return 0;

  values.sort(function (a, b) {
    return a - b;
  });

  const half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
};

export const numberArrayRange = (from: number, to: number): number[] => {
  const years = [];
  for (let x = from; x <= to; x++) {
    years.push(x);
  }
  return years;
};

export const calculateCuttingMeans = (
  data: number[],
  trimAmount: number
): number => {
  if (data.length === 0) return 0;
  const sortedData = data.sort((a, b) => a - b);
  const trimCount = Math.round((trimAmount * data.length) / 2);
  const trimData = sortedData.slice(trimCount, data.length - trimCount);
  return trimData.reduce((a, b) => a + b, 0) / trimData.length;
};

export const moveItemInArray = (
  arr: any[],
  oldIndex: number,
  newIndex: number
) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};
