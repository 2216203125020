import Axios from "axios";
import User from "@/entity/User";
import { TableQuery } from "@/components/data-table/types";
import { ListResponseEntity } from "@/services/types";
import filterQueryParams from "@/services/filterQueryParams";
import { ALL_ITEMS_PER_PAGE } from "@/constants";

export default class UserService {
  public static async find(
    query: TableQuery
  ): Promise<ListResponseEntity<User>> {
    const response = await Axios.get("/api/v2/users", {
      params: {
        ...filterQueryParams(query),
      },
    });

    return response.data;
  }

  public static async findAll(): Promise<User[]> {
    const response = await Axios.get("/api/v2/users", {
      params: {
        ...filterQueryParams({ itemsPerPage: ALL_ITEMS_PER_PAGE }),
      },
    });

    return response.data.content;
  }

  public static async findAllEnabled(): Promise<User[]> {
    const response = await Axios.get("/api/v2/users", {
      params: {
        ...filterQueryParams({
          itemsPerPage: ALL_ITEMS_PER_PAGE,
          filterBy: { enabled: true },
        }),
      },
    });

    return response.data.content;
  }

  public static async findOne(userId: string | number): Promise<User> {
    const response = await Axios.get(`/api/v2/users/${userId}`);
    return response.data;
  }

  public static async create(user: User): Promise<User> {
    const response = await Axios.post("/api/v2/users", user);
    return response.data;
  }

  public static async update(
    userId: string | number,
    user: User
  ): Promise<User> {
    const response = await Axios.put(`/api/v2/users/${userId}`, user);
    return response.data;
  }

  public static async resetPassword(email: string): Promise<void> {
    const response = await Axios.put("/api/v2/users/resetPassword", { email });
    return response.data;
  }

  public static async setPassword(
    token: string,
    password: string
  ): Promise<void> {
    const response = await Axios.put(
      "/api/v2/users/password",
      { password },
      {
        params: {
          token,
        },
      }
    );
    return response.data;
  }

  public static async getMe(): Promise<User> {
    const response = await Axios.get("/api/v2/users/me");
    return response.data;
  }
}
