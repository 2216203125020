







import { Vue, Component } from "vue-property-decorator";
import GlobalSnackbar from "@/components/GlobalSnackbar.vue";

@Component({
  components: {
    GlobalSnackbar,
  },
})
export default class App extends Vue {}
