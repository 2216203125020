import axios from "axios";
import router from "@/routing/Router";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      await router.push({ name: "login" });
    }

    return Promise.reject(error);
  }
);
